@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  html {
    font-family: "inter";
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "inter";
    src: url("./Assets/Fonts/Inter-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
  }
}

@layer components {
  .mesh-gradient {
    background: linear-gradient(
      258.38deg,
      #ff6a25 -17.25%,
      #cd42ff 48.57%,
      #8fb9fc 109.33%
    );
  }

  .mesh-text-gradient {
    background: linear-gradient(
      to left,
      #ff6a25 -46%,
      #cd42ff 78%,
      #8fb9fc 107%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0b0d0f;
  color: #f0f8ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wallet-adapter-button-trigger {
  background: linear-gradient(
    258.38deg,
    #ff6a25 -17.25%,
    #cd42ff 48.57%,
    #8fb9fc 109.33%
  );
  color: #fff;
}

.wallet-adapter-button {
  padding: 8px 16px;
  height: 40px !important;
  border-radius: 2px;
}

.button-hover:hover {
  box-shadow: 0px 0px 18px 13px rgba(205, 66, 255, 0.2);
}

.button-hover-swap:hover {
  box-shadow: 0px 0px 14px 5px rgba(205, 66, 255, 0.2);
}

.accent-shadow {
  box-shadow: 0px 0px 14px 5px rgba(205, 66, 255, 0.2);
}

.info-toast-body {
  align-items: flex-start !important;
  font-family: "inter" !important;
}

.search-field {
  width: 260px;
  display: block;
  padding: 9px 4px 9px 40px;
  background: #12151a url("Assets/Svgs/search-status.svg") no-repeat 13px center;
  outline: none;
}
.search-field::-webkit-search-cancel-button {
  margin-left: 8px;
}
.gradient-border-pagination {
  border-image: linear-gradient(
      258.38deg,
      #ff6a25 -17.25%,
      #cd42ff 48.57%,
      #8fb9fc 109.33%
    )
    10;
}
.gradient-border {
  position: relative;
  padding: 1px;
  background: linear-gradient(
    258.38deg,
    #ff6a25 -17.25%,
    #cd42ff 48.57%,
    #8fb9fc 109.33%
  );
  margin: 1px;
  border-radius: 9px;
}

.gradient-border-secondary {
  background: #a5b6c8;
}

.gradient-border-transparent {
  background: transparent;
}

.gradient-border::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border-radius: inherit;
  background-image: linear-gradient(
    258.38deg,
    #ff6a25 -17.25%,
    #cd42ff 48.57%,
    #8fb9fc 109.33%
  );
}

.collapse-custom-arrow::after {
  top: 11px !important;
  right: 5px !important;
}

.accent-on-hover:hover {
  filter: brightness(0) saturate(100%) invert(38%) sepia(100%) saturate(3386%)
    hue-rotate(263deg) brightness(102%) contrast(102%);
}

.icon {
  transition: transform 0.3s ease-in-out;
}

.icon:hover {
  transform: rotate(30deg); /* Rotate on hover */
}

.preloader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  padding: 10px;
  border: 1px solid grey;
  cursor: pointer;
}
/* + .gradient-radio-label::before  */
.gradient-radio:checked {
  background: linear-gradient(
    258.38deg,
    #ff6a25 -17.25%,
    #cd42ff 48.57%,
    #8fb9fc 109.33%
  );
  border-color: #cd42ff;
  color: #f0f8ff;
}
.gradient-radio:hover {
  border-color: #cd42ff;
}

.light-gradient-background {
  background: linear-gradient(
    258.38deg,
    rgba(255, 106, 37, 0.2) -17.25%,
    rgba(205, 66, 255, 0.2) 48.57%,
    rgba(143, 185, 252, 0.2) 109.33%
  );
}

@media (max-width: 768px) {
  .wallet-adapter-dropdown-list {
    top: -410% !important;
  }
}
