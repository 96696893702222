.modal-tokens {
  padding: 1.5rem 0;
}

.token-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  border: 1px solid transparent;
  margin-bottom: 8px;
  border-radius: 8px;
}

.token-display:hover {
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.token {
  display: flex;
  align-items: center;
  scrollbar-width: thin;
  scrollbar-color: #cfd8dc;
}
/* .token::-webkit-scrollbar {
  width: 15px;
}
.token::-webkit-scrollbar-track {
  background: #cfd8dc;
}
.token::-webkit-scrollbar-thumb {
  background-color: #90a4ae;
  border-radius: 6px;
  border: 3px solid #90a4ae;
} */
::-webkit-scrollbar {
  width: 11px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #12151a; /* color of the track */
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    258.38deg,
    rgba(255, 106, 37, 0.15) -17.25%,
    rgba(205, 66, 255, 0.15) 48.57%,
    rgba(143, 185, 252, 0.15) 109.33%
  ); /* #0b0d0f; /* color of the thumb */
  border-radius: 16px; /* roundness of the thumb */
}

.token-symbol {
  color: #fff;
  font-weight: 700;
}

.token-name {
  color: #617089;
}

.token-balance {
  color: #fff;
}

.bd {
  margin-top: 15px;
  height: 35vh;
  max-height: 35vh;

  /* ::-webkit-scrollbar {
    background-color: red;
    height: 4px;
  } */
  /* &::-webkit-scrollbar-track {
       background-color: transparent;
     } */
  /* &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 4px;
  } */
}

.token-list {
  background-color: #1a1a2e;
  color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px; /* Adjust the width as necessary */
}

.token {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.hover-token:hover {
  background: linear-gradient(
    258.38deg,
    rgba(255, 106, 37, 0.15) -17.25%,
    rgba(205, 66, 255, 0.15) 48.57%,
    rgba(143, 185, 252, 0.15) 109.33%
  );
}

.token:last-child {
  margin-bottom: 0;
}

.token-logo img {
  width: 35px; /* Adjust logo size as necessary */
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

.token-info {
  display: flex;
  flex-direction: column;
}

.token-name {
  font-weight: bold;
}

.token-description {
  font-size: 0.8em;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #29364d;
  border-radius: 5px;
  font-size: 1em;
  padding-left: 2.5rem;
  color: #f4f4f4;
}

.search-field {
  width: 260px;
  display: block;
  border: 1px solid #29364d;
  padding: 9px 4px 9px 40px;
  background: transparent url("Assets/Svgs/search-status.svg") no-repeat 13px
    center;
  outline: none;
}
input[type="search"]::placeholder {
  color: #f4f4f4;
  font-size: 14px;
  font-weight: 500;
}

.divider-class {
  border-color: #29364d;
}

.gradient-border-image {
  border: 1px solid #ff6a25;
  border-radius: 4px;
  border-image: linear-gradient(
      258.38deg,
      #ff6a25 -17.25%,
      #cd42ff 48.57%,
      #8fb9fc 109.33%
    )
    1;
}

@media only screen and (max-width: 500px) {
  .token-logo img {
    width: 20px; /* Adjust logo size for small screens */
    height: 20px;
    margin-right: 5px; /* Adjust margin for small screens */
  }
}

.input-container {
  position: relative;
}

.input-with-gradient-border {
  width: 100%;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 8px;
  background-color: #0b0d0f;
  transition: border-color 0.3s ease;
}

.input-with-gradient-border:focus {
  outline: none;
  border-image: linear-gradient(
      258.38deg,
      #ff6a25 -17.25%,
      #cd42ff 48.57%,
      #8fb9fc 109.33%
    )
    1;
}

.sol-text {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-left: 6px;
  transform: translateY(-50%);
  background-color: #0b0d0f;
  z-index: 10;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.transition {
  overflow: hidden;
  max-height: 0;
  transition: max-height 2s ease-in-out;
}
.transition.expanded {
  max-height: 1000px; /* Set to a value larger than the content */
}
/* .slide-in {
  max-height: 1000px; /* Set to a value larger than the content */
/* } */

/* .slide-out {
  max-height: 0;
} */
